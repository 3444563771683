import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Grid,
  Box,
  Stack,
} from "@mui/material";
import { useLocalization } from "gatsby-theme-i18n";
import _ from "lodash";
import styled from "@emotion/styled";
import Container from "../../../components/Container";
import NewLayout from "../../../components/NewLayout";
import { headers } from "../../../utils/navbar";
import ipoInformationDocs from "../../../cms/ipoInformationDocs/ipoInformationDocs";
import InterestedItems from "../../../components/InterestedItems";
import ifrImage from "../../../images/ifr-awards.png";
import DocumentList from "../../../components/DocumentList";
import trafficImage from "../../../images/investors/investment-case/traffic.png";
import buildingImage from "../../../images/investors/investment-case/buildings.png";
import cityImage from "../../../images/investors/investment-case/city.png";
import artImage from "../../../images/investors/investment-case/art.png";
import glassImage from "../../../images/investors/investment-case/glass.png";
import BoxDetails from "../../../components/BoxDetails";

const summaryTableData = () => [
  { label: "Price range - €/share", value: "€10.50 - €12.00" },
  { label: "Offering period", value: "16 – 22 April 2021" },
  {
    label: "Announcement of the price of the Offering",
    value: "23 April 2021",
  },
  {
    label: "First day of trading in the Company´s shares",
    value: "23 April 2021",
  },

  { label: "Settlement date", value: "27 April 2021" },
];

const detailsContent = () => [
  {
    title: `
        A leading global scaled WealthTech, with global reach and local presence
        ideally positioned to capture market share in a still fragmented, but
        structurally growing, market
      `,
    description: (
      <StyledStack spacing={1}>
        <Typography>
          Allfunds is one of the largest B2B WealthTech platforms, with over
          €1.2 trillion AuA. With presence in 4 continents, we have leading
          market share positions in Europe, the Middle East and Singapore [1]
          and have experienced strong AuA growth in recent years across all
          geographies.
        </Typography>
        <Typography>
          The traditional wealth management landscape is characterised by a
          fragmented patchwork of providers and legacy systems, which Allfunds
          believes leads to sub-optimal outcomes for both Distributors and Fund
          Houses. As such, Allfunds has set out to fundamentally change the
          industry by building a single fully-integrated global platform,
          providing Fund Houses with a single point of access to the largest
          global distribution network.
        </Typography>
        <Typography>
          We believe in the importance of being close to our clients to
          understand their needs. Thanks to our network of global services
          supported through 15 local offices, Allfunds has built long-lasting
          relationships with Distributors and Fund Houses. This has contributed
          to the Group’s success in capturing market share across the
          geographies in which it operates.
        </Typography>
        <Typography>[1] Based on AuA in 2020</Typography>
      </StyledStack>
    ),
    image: buildingImage,
  },
  {
    title: `
        A one-stop-shop with a unique value proposition, game-changing digital
        tools and proprietary technology enabling continued focus on operating
        efficiencies
      `,
    description: (
      <StyledStack spacing={1}>
        <Typography>
          We have integrated large parts of the wealth management value chain
          into a simple and easy to use one-stop-shop platform across
          distribution, dealing, custody and administration services, and have
          expanded our offer into other value-add areas like data and analytics.
        </Typography>
        <Typography>
          A key driver of Allfunds’ competitive differentiation and growth is
          the compelling value proposition it delivers to both sides of its
          marketplace business model.
        </Typography>
        <Typography>
          Fund Houses and Distributors gain access to industry-leading
          functionality through Allfunds Connect, a subscription-based
          SaaS-enabled offering of data-centric services. Through different
          application programming interfaces, Allfunds Connect is able to
          develop bespoke solutions that are fully integrated into Fund Houses’
          and Distributors’ IT systems, providing them with an end-to-end
          solution to suit their needs.
        </Typography>
        <Typography>
          As a fully-invested, scalable platform, we onboard new clients at very
          low marginal costs and therefore at highly competitive ratess.
        </Typography>
        <Typography>
          Our proprietary technology is designed to guarantee seamless
          integration of solutions into the Allfunds platform to provide the
          best possible client experience and we will continue to leverage our
          technology and operations infrastructure as the business grows to
          drive cost optimisations.
        </Typography>
        <Typography>
          We continue to invest in our platform to maintain operational
          efficiency and high quality service.
        </Typography>
      </StyledStack>
    ),
    image: trafficImage,
  },
  {
    title: `
        Allfunds offers a simple and attractive pricing model paired with a
        powerful “flywheel” effect and continuous innovation
      `,
    description: (
      <StyledStack spacing={1}>
        <Typography>
          Allfunds believes that it has a simple and attractive business model,
          whereby Distributors benefit from a buy-free model in core services
          such as trading, dealing, settlement and administration while paying a
          subscription fee for value-added services. Our comprehensive suite of
          services attracts new distributors to join our platform, providing
          incremental flows to fund houses, thus incentivising more fund houses
          to join Allfunds to capture increased sales from a growing base of
          Distributors. We refer to this as the “flywheel” effect.
        </Typography>
        <Typography>
          Furthermore, Allfunds believes that its innovative Allfunds Connect
          offering accelerates the flywheel effect by creating additional
          incentives for Distributors and Fund Houses to continue using and
          increasing their use of the Group’s services and solutions.
        </Typography>
      </StyledStack>
    ),
    image: artImage,
  },
  {
    title: `
        Superior financial profile underpinned by best-in-class growth,
        significant operating leverage and proven M&A track record
      `,
    description: (
      <StyledStack spacing={1}>
        <Typography>
          Our superior financial profile is the result of a compelling
          combination of strong top-line growth, profitability and high cash
          conversion at scale.
        </Typography>
        <Typography>
          The Group has several compelling building blocks of growth, which have
          supported its track record of double-digit AuA growth, net revenue
          growth and Adjusted EBITDA growth.
        </Typography>
        <Typography>
          Allfunds was able to translate this strong growth in AuA into high
          top-line growth, with net revenues growing at a CAGR of 24% from 2016
          to 2020 (based on pro forma 2020 net revenue), of which c.12% was
          organic and the rest was due to M&A. Our business model has proven
          resilience to economic cycles and we continue to improve operating
          efficiencies, leveraging our technology and operations infrastructure
          as the business grows.
        </Typography>
        <Typography>
          Allfunds has materially increased its scale, capabilities, and
          geographical footprint through a number of value-accretive
          acquisitions in recent years, pursuing a strategy focused on both
          opportunistic bolt-on acquisitions and transformative M&A.
        </Typography>
        <Typography>
          We expect further consolidation in the wealth management market and
          continue to focus on selected value-accretive M&A opportunities.
        </Typography>
      </StyledStack>
    ),
    image: glassImage,
  },
  {
    title: `
        A founder-led visionary management team fostering an entrepreneurial
        culture`,
    description: (
      <StyledStack spacing={1}>
        <Typography>
          Our founder and CEO, Juan Alcaraz leads a highly experienced and
          entrepreneurial management team with complementary skill-sets and
          proven track records of driving innovation.
        </Typography>
      </StyledStack>
    ),
    image: cityImage,
  },
];

function IpoInvestorsPage() {
  const { locale } = useLocalization();
  const docs = ipoInformationDocs[locale].filter(({ attributes: { category } }) => category === "Key_Documents");
  const allfundsProspectusDocs = ipoInformationDocs[locale].filter(({ attributes: { category } }) => category === "Allfunds_prospectus");
  const pressDocs = ipoInformationDocs[locale].filter(({ attributes: { category } }) => category === "Press_Releases");
  const interestedItems = [
    { ..._.get(headers(), "investors.elements.financial_information_reports.elements.financial_reports") },
    { ..._.get(headers(), "investors.elements.financial_information_reports.elements.bank_reports") },
    { ..._.get(headers(), "investors.elements.contact") },
  ];

  return (
    <NewLayout byDefault apps="investors.financial_information_reports.ipo">
      <Container pb={8}>
        <Typography mt={3} mb={{ xs: 3, sm: 7 }} variant="h2">
          IPO Information
        </Typography>
        <Box mb={2}>
          <Grid container spacing={5}>
            <Grid item xs={12} sm={6}>
              <Typography mb={1} variant="h3">
                Summary of the Offering
              </Typography>
              <Table>
                <TableBody>
                  {summaryTableData().map(({ label, value }) => (
                    <TableRow>
                      <TableCell>
                        <Typography variant="small">{label}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="small">{value}</Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h3">Allfunds Prospectus</Typography>
              <Typography my={1} fontSize={16}>
                On 7 April 2021, Allfunds announced the intention to launch an
                offering and list its shares on Euronext Amsterdam, through a
                private placement of existing shares. Prospectus and the price
                range were made public on 16 April. First trading day for the
                Allfunds share (ALLFG) was 23 April 2021.
              </Typography>
              <DocumentList items={allfundsProspectusDocs} direction="row" />
            </Grid>
          </Grid>
        </Box>
        <Box mt={{ xs: 4, sm: 8 }}>
          <DocumentList title="Press Releases" items={pressDocs} />
        </Box>
      </Container>
      <Box p={4} sx={{ background: (theme) => theme.palette.primary.main }}>
        <Container>
          <Box
            maxWidth={800}
            display="flex"
            justifyContent="center"
            margin="0 auto"
          >
            <Grid
              container
              spacing={2}
              justifyContent="center"
              alignItems="center"
              textAlign={{ xs: "center", sm: "start" }}
            >
              <Grid item xs={12} sm={3}>
                <img alt="" height={120} src={ifrImage} />
              </Grid>
              <Grid item xs={12} sm={9}>
                <WhiteTypography variant="h3">
                  Allfunds wins EMEA IPO of the year 2021
                </WhiteTypography>
                <WhiteTypography mt={1} fontSize={16}>
                  IFR, International Financing Review has recognized Allfunds’
                  IPO as the most successful in 2021. The accolade is awarded on
                  the basis of both the success of a listing as well as the
                  continued growth and performance of shares thereafter.
                </WhiteTypography>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
      <Container py={{ xs: 5, sm: 10 }}>
        <Typography mb={2} variant="h2">
          Investment case
        </Typography>
        <Typography mb={2} variant="h3">
          Our Investment Highlights
        </Typography>
        <Stack mt={5} spacing={2}>
          {detailsContent().map(({ title, description, image }) => (
            <BoxDetails title={title} description={description} image={image} />
          ))}
        </Stack>
      </Container>
      <Box
        p={{ xs: 2, sm: 4 }}
        sx={{ background: (theme) => theme.palette.primary.main }}
      >
        <Container>
          <Grid item xs={12}>
            <DocumentList
              items={docs}
              title="Key Documents"
              description="Please find below the key documents relating to the Allfunds IPO."
              direction="row"
              isDark
            />
          </Grid>
        </Container>
      </Box>
      <Container pb={8}>
        <InterestedItems items={interestedItems} />
      </Container>
    </NewLayout>
  );
}

const WhiteTypography = styled(Typography)`
  color: white;
`;

const StyledStack = styled(Stack)`
  * {
    font-size: 18px;
  }
`;

export default IpoInvestorsPage;
